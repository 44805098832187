import './consent-style-overrides.css';

import { OSANO_SCRIPT_URL } from './constants';
import { initializeDataWidget } from './data-widget';
import {
  ensureGtag,
  initializeDefaultConsent,
  initializeGtm,
  loadScript,
} from './utils';

const main = () => {
  ensureGtag();
  initializeDefaultConsent();
  initializeDataWidget();
  loadScript('osano', OSANO_SCRIPT_URL, initializeGtm);
};

main();
