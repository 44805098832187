export const initializeDataWidget = () => {
  const googleAnalyticsEventsDictionary = {
    AffiliateId: 'affiliate_id_change',
  };

  function getAffiliateDictionaryFromUrlParams() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let affiliateParamsDictionary = {};

    const affiliateId = params['affiliate-id'];
    if (affiliateId && affiliateId.length > 0) {
      affiliateParamsDictionary['AffiliateId'] = affiliateId;
    }

    for (const [key, value] of urlSearchParams.entries()) {
      if (key.startsWith('utm_')) {
        affiliateParamsDictionary[key] = value;
      }
    }

    const clientId = params['client-id'];
    if (clientId && clientId.length > 0) {
      affiliateParamsDictionary['ClientId'] = clientId;
    }

    return affiliateParamsDictionary;
  }

  function replaceOrCreateValuesForAffiliateCookie(
    affiliateUrlParamDictionary
  ) {
    const allCookiesDictionary = document.cookie
      .split(';')
      .map((cookie) => [
        cookie.substring(0, cookie.indexOf('=')),
        cookie.substring(cookie.indexOf('=') + 1),
      ])
      .reduce((acc, cookie) => {
        acc[decodeURIComponent(cookie[0].trim())] = decodeURIComponent(
          cookie[1].trim()
        );
        return acc;
      }, {});

    const affiliateCookieName = 'AffiliateCookie';
    let affiliateCookieValue = allCookiesDictionary[`${affiliateCookieName}`];

    let expiryTime = new Date(Date.now());
    expiryTime.setDate(expiryTime.getDate() + 30);

    for (const affiliateKey of Object.keys(affiliateUrlParamDictionary)) {
      const affiliateValue = affiliateUrlParamDictionary[affiliateKey];
      if (!affiliateValue || affiliateValue.length === 0) {
        continue;
      }

      affiliateCookieValue =
        getUpdatedAffiliateCookieValueAndHandleValueChangedEvent(
          affiliateCookieValue,
          affiliateKey,
          affiliateValue
        );
    }

    document.cookie = `${affiliateCookieName}=${affiliateCookieValue}; path=/; expires=${expiryTime.toString()}`;
  }

  function getUpdatedAffiliateCookieValueAndHandleValueChangedEvent(
    affiliateCookieValue,
    affiliateKey,
    affiliateValue
  ) {
    const newAffiliateKeyValueString = `${affiliateKey}=${affiliateValue}`;

    if (!affiliateCookieValue || affiliateCookieValue.length === 0) {
      sendGoogleAnalyticsEventIfNeeded(affiliateKey, null, affiliateValue);
      return newAffiliateKeyValueString;
    }

    const affiliateKeyValueRegExp = new RegExp(
      // eslint-disable-next-line no-useless-escape
      `${affiliateKey}\\=[[a-zA-Z0-9_\-]*`
    );
    const matchingValues = affiliateCookieValue.match(affiliateKeyValueRegExp);
    const existingKeyValue = matchingValues ? matchingValues.at(0) : undefined;
    if (existingKeyValue) {
      const splitKeyValue = existingKeyValue.split('=');
      const existingValue = splitKeyValue ? splitKeyValue.at(1) : undefined;
      sendGoogleAnalyticsEventIfNeeded(
        affiliateKey,
        existingValue,
        affiliateValue
      );
      return affiliateCookieValue.replace(
        affiliateKeyValueRegExp,
        newAffiliateKeyValueString
      );
    }

    sendGoogleAnalyticsEventIfNeeded(affiliateKey, null, affiliateValue);
    return affiliateCookieValue + `&${newAffiliateKeyValueString}`;
  }

  function sendGoogleAnalyticsEventIfNeeded(key, previousValue, newValue) {
    const eventName = googleAnalyticsEventsDictionary[key];
    if (!eventName || previousValue === newValue) {
      return;
    }

    previousValue = previousValue || '';
    const event = {
      event: eventName,
      previous_value: previousValue,
      new_value: newValue,
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  }

  const affiliateUrlParamDictionary = getAffiliateDictionaryFromUrlParams();

  if (
    affiliateUrlParamDictionary &&
    Object.keys(affiliateUrlParamDictionary).length > 0
  ) {
    replaceOrCreateValuesForAffiliateCookie(affiliateUrlParamDictionary);
  }
};
