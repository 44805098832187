export const OSANO_SCRIPT_URL =
  'https://cmp.osano.com/16BPlHTnpiGGGoFs/1be81aef-df05-42c2-91a2-3a31147250d1/osano.js?variant=three';

export const GTM_BOOTSTRAP_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TXR74VL');`;

export const MESSAGE_MISSING_GTM_ID =
  'element <meta name="gtm-id" content="[id]" /> is missing, ' +
  'remove existing gtm inline scripts and add the meta tag instead';

export const MESSAGE_INVALID_GTM_ID =
  "gtm-id should probably start with 'GTM-'";

export const MESSAGE_GTM_ALREADY_INITIALIZED =
  'gtm has already initialized on the page, remove existing gtm inline scripts ' +
  '(ensuring the presence of <meta name="gtm-id" content="[id]" /> also) to let the bootstrapper load it instead';

export const MESSAGE_DEFAULT_CONSENT_PRESENT_BUT_NOT_FIRST =
  'Default consent has been set but was not the first item in the dataLayer array. ' +
  'This will cause issues with the consent being applied correctly.';

export const MESSAGE_DEFAULT_CONSENT_NOT_FIRST =
  'Default consent was not set first in the dataLayer array. ' +
  'This will cause issues with the consent being applied correctly.';
