// Ignored no-console because importing a library to handle logging is overkill
/* eslint-disable no-console */
import {
  GTM_BOOTSTRAP_SCRIPT,
  MESSAGE_DEFAULT_CONSENT_NOT_FIRST,
  MESSAGE_DEFAULT_CONSENT_PRESENT_BUT_NOT_FIRST,
  MESSAGE_GTM_ALREADY_INITIALIZED,
  MESSAGE_INVALID_GTM_ID,
  MESSAGE_MISSING_GTM_ID,
} from './constants';

export const ensureGtag = () => {
  window.dataLayer = window.dataLayer || [];
  if (!window?.gtag) {
    // MUST be a regular function, NOT an arrow function
    window.gtag = function () {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
  }
};

export const initializeDefaultConsent = () => {
  ensureGtag();

  const defaultConsentIndex = window.dataLayer.findIndex(
    (item: unknown) =>
      Array.isArray(item) && item[0] === 'consent' && item[1] === 'default'
  );

  if (defaultConsentIndex > 0) {
    console.error(MESSAGE_DEFAULT_CONSENT_PRESENT_BUT_NOT_FIRST);
  }

  if (defaultConsentIndex === -1) {
    if (window.dataLayer.length > 0) {
      console.error(MESSAGE_DEFAULT_CONSENT_NOT_FIRST);
    }

    window.gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      personalization_storage: 'denied',
      functionality_storage: 'granted',
      security_storage: 'granted',
      wait_for_update: 500,
    });
    window.gtag('set', 'ads_data_redaction', true);
  }
};

export const initializeGtm = () => {
  const metaTag = document.querySelector('meta[name="gtm-id"]');
  const gtmId = metaTag?.getAttribute('content');

  if (!gtmId) {
    console.error(MESSAGE_MISSING_GTM_ID);
    return;
  }

  if (!gtmId.startsWith('GTM-')) {
    console.warn(MESSAGE_INVALID_GTM_ID);
  }

  const gtmStarted = window.dataLayer.some(
    (item: unknown): item is { event: string } =>
      typeof item === 'object' &&
      item !== null &&
      'event' in item &&
      item.event === 'gtm.js'
  );

  if (gtmStarted) {
    console.error(MESSAGE_GTM_ALREADY_INITIALIZED);
    return;
  }

  loadInlineScript('gtm', GTM_BOOTSTRAP_SCRIPT);
};

export const loadInlineScript = (id: string, content: string) => {
  const script = document.createElement('script');
  script.id = id;
  script.innerHTML = content;
  document.head.appendChild(script);
};

export const loadScript = (id: string, src: string, onLoad?: () => void) => {
  const script = document.createElement('script');
  script.id = id;
  script.src = src;

  if (onLoad) {
    script.onload = onLoad;
  }

  document.head.appendChild(script);
};
